import { desiredOrder } from '../common/Constants';
import { Notification } from '../dto/notification';

export function groupAndSortNotifications(notifications: Notification[]) {
  const groupedNotifications: { [date: string]: Notification[] } = {};

  notifications.forEach((notification) => {
    const dateObject = typeof notification.date === 'string' ? new Date(notification.date) : notification.date;

    const dateKey = dateObject.toDateString();
    if (!groupedNotifications[dateKey]) {
      groupedNotifications[dateKey] = [];
    }
    groupedNotifications[dateKey].push(notification);
  });

  const sortedDates = Object.keys(groupedNotifications).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

  return { groupedNotifications, sortedDates };
}

export const formatDate = (dateString: Date): string => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate());
  const month = String(date.getMonth() + 1);

  return `${month}/${day}`;
};

export const sortByDesiredOrder = (a: any, b: any) => {
  const indexA = desiredOrder.indexOf(a.identifierName);
  const indexB = desiredOrder.indexOf(b.identifierName);

  if (indexA === -1) return 1;
  if (indexB === -1) return -1;

  return indexA - indexB;
};
