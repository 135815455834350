export const addPatientApiPath = '/api/provider-portal/register-user';

export type AddPatientRequestDTO = {
  email: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  phoneNumber: string;
  patientId: string;
  clinicId: string;
  doctorId: string;
  conditions: Array<string>;
};

export type AddPatientResponseDTO = {
  status: number;
  message: string;
};
