import { ApiClientContext } from './context/ApiClientContext';
import { AppRoutes } from './router';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createApiClient } from './common/ApiClient';
import { theme } from './theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const apiClient = createApiClient(process.env.REACT_APP_API_HOST_IP!);

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <ToastContainer />
        <ApiClientContext.Provider value={apiClient}>
          <AppRoutes />
        </ApiClientContext.Provider>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
