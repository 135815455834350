import { createTheme } from '@mui/material/styles';
import './assets/fonts/Archivo-ExtraLight.ttf';
import './assets/fonts/Archivo_Condensed-Light.ttf';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#635992',
      main: '#3C3077',
      dark: '#2A2153',
    },
    secondary: {
      light: '#FBEF75',
      main: '#FBEC53',
      dark: '#AFA53A',
    },
  },
  typography: {
    fontFamily: ['ArchivoLight', 'ArchivoExtraLight'].join(','),
  },
});
