/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  SxProps,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import React, { useState } from 'react';

import { AllPatientsResponseDTO } from '../../dto/patient/patient';
import { AsyncState } from '../../hooks/use-async';
import CancelIcon from '@mui/icons-material/Cancel';
import DatePicker from '@mui/lab/DatePicker';
import { DatePickerComponent } from '../common/DatePicker';
import { GridSortModel } from '@mui/x-data-grid';
import { PaginationModel } from '../../containers/patient/PatientList';
import { PatientListBox } from './PatientListBox';
import { qwltMessages } from '../../common/Constants';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

const outerLayout: SxProps<Theme> = {
  minWidth: '1366px',
  display: 'flex',
  width: '100%',
  height: 'calc(100vh - 60px)',
  backgroundColor: '#ECF1F4',
};

const outerGrid: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  backgroundColor: '#FAFCFE',
};

const outerDialogBox: SxProps<Theme> = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
};

const innerDialogBox: SxProps<Theme> = {
  display: 'flex',
  width: '90%',
  height: '90%',
  borderRadius: '10px',
  backgroundColor: 'white',
  flexDirection: 'column',
};

const containerBox: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100px',
  alignSelf: 'center',
  width: '90%',
};

const searchBox: SxProps<Theme> = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
};

const outerTabContainer: SxProps<Theme> = {
  display: 'flex',
  width: '360px',
  height: '50px',
  borderRadius: '8px',
  overflow: 'hidden',
  border: '1px solid #40256B',
  backgroundColor: '#8C8CA1',
};

const selectedTabContainer: SxProps<Theme> = {
  flex: 1,
  backgroundColor: '#40256B',
  fontFamily: 'ArchivoExtraLight',
  fontStyle: 'normal',
  fontSize: '16px',
  color: '#fff',
  fontWeight: '700',
  textTransform: 'none',
  letterSpacing: '1.5px',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: '#40256B',
  },
};

const tabContainer: SxProps<Theme> = {
  flex: 1,
  backgroundColor: '#fff',
  fontFamily: 'ArchivoExtraLight',
  fontStyle: 'normal',
  fontSize: '16px',
  fontWeight: '700',
  color: '#40256B',
  textTransform: 'none',
  letterSpacing: '1.5px',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: '#fff',
  },
};

const theme = createTheme({
  typography: {
    fontFamily: 'ArchivoExtraLight',
    fontSize: 18,
  },
});

const searchField: SxProps<Theme> = {
  width: '100%',
  height: '55px',
  backgroundColor: 'white',
  mr: 1,
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
      border: 'none',
      backgroundColor: 'white',
    },
    '&:hover fieldset': {
      borderRadius: '8px',
      border: 'none',
      backgroundColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderRadius: '8px',
      border: 'none',
      backgroundColor: 'white',
    },
  },
};

const dialogBoxHeader: SxProps<Theme> = {
  display: 'flex',
  height: '90px',
  backgroundColor: 'white',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
};

const addPatientText: SxProps<Theme> = {
  flex: 1,
  fontFamily: 'ArchivoLight',
  letterSpacing: '0.5px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '10px',
};

const totalCountText: SxProps<Theme> = {
  flex: 1,
  fontFamily: 'ArchivoExtraLight',
  letterSpacing: '0.5px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '10px',
  color: '#8C8CA1',
};

const addPatientBox: SxProps<Theme> = {
  fontFamily: 'ArchivoLight',
  mr: 3,
  textTransform: 'none',
  height: '50px',
  backgroundColor: '#EE2D76',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  letterSpacing: '1.5px',
  border: 'none',
  borderRadius: '8px',
  paddingRight: '30px',
  paddingLeft: '30px',
  outline: 'none',
  color: 'white',
  ':hover': {
    backgroundColor: '#EE2D76',
  },
};

const breadcrumbContainer: SxProps<Theme> = {
  display: 'flex',
  width: '90%',
  alignSelf: 'center',
};

const breadcrumbText: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  ml: 2,
  flex: 1,
};

const breadcrumbBox: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const breadcrumbOuter: SxProps<Theme> = {
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '35px',
  height: '50px',
  paddingRight: '20px',
  paddingLeft: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: '1px',
  fontWeight: '700',
};

const breadcrumbCancel: SxProps<Theme> = {
  cursor: 'pointer',
  marginLeft: '8px',
};

const searchButton: SxProps<Theme> = {
  fontFamily: 'ArchivoLight',
  textTransform: 'none',
  height: '55px',
  backgroundColor: '#EE2D76',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  letterSpacing: '1.5px',
  border: 'none',
  borderRadius: '8px',
  paddingRight: '30px',
  paddingLeft: '30px',
  outline: 'none',
  color: 'white',
  ':hover': {
    backgroundColor: '#EE2D76',
  },
};

interface Condition {
  conditionId: string;
  label: string;
}

export type PatientListComponentProps = {
  patientList: AsyncState<AllPatientsResponseDTO>;
  paginationModel: PaginationModel;
  searchInput?: string;
  sortValue?: GridSortModel;
  onSearchInputChange: (event: string) => void;
  onHandleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSortChange?: (sortValue: GridSortModel) => void;
  onPaginationModelChange: (paginationModel: PaginationModel) => void;
  onAddPatientClick: () => void;
  onSearchClick: () => void;
};

export const PatientListComponent: React.FC<PatientListComponentProps> = ({
  patientList,
  paginationModel,
  sortValue,
  searchInput,
  onSortChange,
  onHandleKeyDown,
  onSearchInputChange,
  onSearchClick,
  onPaginationModelChange,
  onAddPatientClick,
}) => {
  const totalItemCount = patientList.result?.data?.totalResults ?? 0;

  // TODO: move this state to the container
  const [selectedTab, setSelectedTab] = React.useState('myPatients');
  const [selectedCondition, setSelectedCondition] = React.useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [selectedConditions, setSelectedConditions] = useState<Condition[]>([]);

  const handleAutocompleteChange = (event: React.SyntheticEvent, newValue: Condition[]) => {
    setSelectedConditions(newValue);
  };

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleChangeCondition = (event: any) => {
    setSelectedCondition(event.target.value);
  };
  const handleChangeTab = (tabName: 'myPatients' | 'allPatients') => {
    setSelectedTab(tabName);
  };

  const handleCancel = (index: number) => {
    const newSelectedConditions = [...selectedConditions];
    newSelectedConditions.splice(index, 1);
    setSelectedConditions(newSelectedConditions);
  };

  const handleClearAll = () => {
    setSelectedConditions([]);
  };

  return (
    <Box sx={outerLayout}>
      <Grid container>
        <Grid sx={outerGrid} item xs={12}>
          <Box sx={containerBox}>
            {/* Search and Filter */}
            <Box sx={searchBox}>
              <OutlinedInput
                id='input-with-icon-adornment'
                sx={searchField}
                value={searchInput}
                onKeyDown={onHandleKeyDown}
                onChange={(text) => onSearchInputChange(text.target.value)}
                placeholder={qwltMessages.search}
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <Button onClick={() => onSearchClick()} sx={searchButton}>
                {qwltMessages.search}
              </Button>
              {/* <Autocomplete
                key={selectedConditions.length}
                disablePortal
                multiple
                filterSelectedOptions
                id='combo-box-demo'
                options={conditionsList}
                value={selectedConditions}
                onChange={handleAutocompleteChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={qwltMessages.conditions}
                    placeholder={selectedConditions.length === 0 ? qwltMessages.conditions : ''}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <React.Fragment />,
                      endAdornment: null,
                    }}
                  />
                )}
                sx={searchField}
              /> */}

              {/* <DatePickerComponent label='From' />
              <DatePickerComponent label='To' /> */}
            </Box>
            {/* Tabs */}
            {/* <Box sx={outerTabContainer}>
              <Button
                variant='contained'
                sx={selectedTab === 'myPatients' ? selectedTabContainer : tabContainer}
                onClick={() => handleChangeTab('myPatients')}
              >
                {qwltMessages.myPatients}
              </Button>
              <Button
                variant='contained'
                sx={selectedTab === 'allPatients' ? selectedTabContainer : tabContainer}
                onClick={() => handleChangeTab('allPatients')}
              >
                {qwltMessages.allPatients}
              </Button>
            </Box> */}
          </Box>
          {/* <Breadcrumbs aria-label='breadcrumb' sx={breadcrumbContainer} separator={null}>
            {selectedConditions.map((condition, index) => (
              <Box key={index} sx={breadcrumbBox}>
                <Typography sx={breadcrumbOuter} color='text.primary'>
                  {condition.label}
                  <CancelIcon sx={breadcrumbCancel} onClick={() => handleCancel(index)} />
                </Typography>
              </Box>
            ))} */}
          {/* {selectedConditions.length > 0 && (
              <Box sx={breadcrumbBox}>
                <Typography sx={breadcrumbOuter} color='text.primary' onClick={handleClearAll}>
                  {qwltMessages.clearAll}
                </Typography>
              </Box>
            )}
          </Breadcrumbs> */}
          <Box sx={outerDialogBox}>
            <Box sx={innerDialogBox}>
              <Box sx={dialogBoxHeader}>
                <Box sx={breadcrumbText}>
                  <Typography align='left' marginBottom='26px' variant='subtitle1' component='p' sx={addPatientText}>
                    {qwltMessages.mypatients}
                  </Typography>
                  <Typography align='left' variant='subtitle1' component='p' sx={totalCountText}>
                    {totalItemCount}
                    {qwltMessages.total}
                  </Typography>
                </Box>
                <Button onClick={onAddPatientClick} sx={addPatientBox}>
                  {qwltMessages.addPatientText}
                </Button>
              </Box>
              <Box sx={{ flex: 1, mt: '20px' }}>
                <PatientListBox
                  patientList={patientList}
                  paginationModel={paginationModel}
                  sortValue={sortValue}
                  onSortChange={onSortChange}
                  onPaginationModelChange={onPaginationModelChange}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
