import { UUID } from 'crypto';
import { BehaviourMetrics } from '../../components/patient/PatientDetails';

export const fetchConditionsApiPath = '/api/provider-portal/fetch-conditions';
export const fetchPatientsApiList = '/api/users/get-all-user-details';
export const fetchDoctorsList = '/api/provider-portal/fetch-doctors';
export const fetchBehaviourMetrics = '/api/metric/behaviour-tracking-metrics';

export type AllPatientsResponseDTO = {
  status: number;
  message: string;
  data: {
    users: Array<PatientUserResponseDTO>;
    totalResults: number;
    totalPages: number;
    currentPage: number;
  };
};

export type AllPatientsRequestDTO = {
  clinicId: string;
  page: number;
  pageSize: number;
  searchLiteral?: string;
  sort?: Array<{ field: string; value: string }>;
};

export type FetchConditionsResponseDTO = {
  message: string;
  status: number;
  data: Array<ConditionResponseDTO>;
};

export type FetchDoctorsResponseDTO = {
  message: string;
  status: number;
  data: Array<DoctorsResponseDTO>;
};

export type PatientUserResponseDTO = {
  rowNumber: number;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  gender: string | null;
  timeZone: string | null;
  dob: string;
  userConditions: Array<UserConditionResponseDTO>;
  registeredOn: string;
  status: string;
  externalUserId: string;
};

export type UserConditionResponseDTO = {
  id: string;
  userId: string;
  conditionId: string;
  createdAt: string;
  updatedAt: string;
  conditions: ConditionResponseDTO;
};

export type ConditionResponseDTO = {
  id: string;
  abbreviation: string;
  conditionName: string;
  createdAt: string;
  updatedAt: string;
};

export type DoctorsResponseDTO = {
  id: string;
  name: string;
  designation: string;
};

export type Details = {
  id?: string;
  patient?: string;
  age?: string;
  gender?: string;
  registeredOn?: string;
  userConditions?: Array<UserConditionResponseDTO>;
  phoneNumber?: string;
  email?: string;
  userId?: string;
};

export type BehaviourMetricsRequestDTO = {
  userId: UUID;
  rangeType: 'WEEKLY' | 'MONTHLY' | 'CUSTOM';
  rangeValue: number;
  startDate?: Date;
  endDate?: Date;
  identifierName?: string;
};

export type BehaviourMetricsResponseDTO = {
  userId: UUID;
  metrics: BehaviourMetrics[];
  message: 'success' | 'failed';
};
