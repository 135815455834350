import { useContext, useState } from 'react';
import { NotificationsComponent } from '../../components/patient/NotificationsPage';
import { AxiosResponse } from 'axios';
import { ApiClientContext } from '../../context/ApiClientContext';
import { useAsyncEffect } from '../../hooks/use-async';
import {
  Notification,
  NotificationsRequestDTO,
  NotificationsResponse,
  fetchNotifications,
} from '../../dto/notification';
import { useAuth } from '../../hooks/use-auth';
import { PATIENT_DETAILS } from '../../router';
import { useNavigate } from 'react-router-dom';
import { Details } from '../../dto/patient/patient';
import { localStorageLiterals } from '../../common/Constants';

export const NotificationsContainer: React.FC = () => {
  const apiClient = useContext(ApiClientContext)!;
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const data: NotificationsRequestDTO = {
    clinicId: currentUser.clinicId,
    doctorId: currentUser.doctorId,
  };
  const [selectedItems, setSelectedItems] = useState<Notification[]>([]);
  const handleItemClick = (notification: Notification) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.some((item) => item === notification)
        ? prevSelectedItems.filter((item) => item !== notification)
        : [...prevSelectedItems, notification]
    );
  };

  const [result] = useAsyncEffect<NotificationsResponse>({
    fn: async () => {
      const result = await apiClient.post<NotificationsRequestDTO, AxiosResponse<NotificationsResponse, any>, any>(
        fetchNotifications,
        data
      );
      return result.data;
    },
    dependencies: [],
  });

  const handleViewProfileClick = (notification: Notification) => {
    console.log(notification);
    if (notification.profileData?.id) {
      const localPatientDetails: Details = {
        id: notification.profileData.id,
        patient: notification.profileData.patient,
        age: notification.profileData.age,
        gender: notification.profileData.gender,
        registeredOn: notification.profileData.registeredOn,
        userConditions: notification.profileData.conditions,
        phoneNumber: notification.profileData.phoneNumber,
        email: notification.profileData.email,
        userId: notification.profileData.userId,
      };
      localStorage.setItem(localStorageLiterals.patientDetails, JSON.stringify(localPatientDetails));
      navigate(PATIENT_DETAILS);
    }
  };
  const notifications = result?.result?.notifications || [];
  return (
    <>
      <NotificationsComponent
        notifications={notifications}
        isLoading={result.isLoading}
        hasError={result.error}
        selectedItem={selectedItems}
        onItemClick={handleItemClick}
        onViewProfileClick={handleViewProfileClick}
      />
    </>
  );
};
