export const loginApiPath = '/api/provider-portal/login';

export type LoginResponseDTO = {
  sessionToken: string;
  sessionId: string;
  sessionStatus: string;
  doctorId: string;
  clinicId: string;
  message: string;
};

export type LoginRequestDTO = {
  email: string;
  password: string;
};
