/* eslint-disable react/prop-types */
import { Box, Button, Grid, SxProps, TextField, Theme, Typography } from '@mui/material';

import { LoginRequestDTO } from '../../dto/auth/signIn';
import backgroundSpot from '../../assets/icons/backgroundSpot.svg';
import objectLogo from '../../assets/icons/object.svg';
import quiltLogo from '../../assets/icons/quiltLogoExport.svg';
import { qwltMessages } from '../../common/Constants';
import smiley from '../../assets/icons/smiley.svg';

const sampleDivStyle: SxProps<Theme> = {
  display: 'flex',
  minWidth: '1366px',
  height: '100vh',
  background: `url(${backgroundSpot}) left, linear-gradient(to right, #4b256b 0%, #4b256b 50%, #4b256b 50%, #4b256b 100%)`,
  backgroundSize: '100% , auto',
  backgroundRepeat: 'no-repeat',
};

const formBox: SxProps<Theme> = {
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const formInnerBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '75%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const welcomeBack: SxProps<Theme> = {
  fontFamily: 'ArchivoLight',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '16px',
  marginBottom: '50px',
};

const labelName: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  fontStyle: 'normal',
  fontSize: '20px',
  fontWeight: 700,
  color: 'black',
  lineHeight: '5vh',
  letterSpacing: 1,
};

const forgotPassword: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  textAlign: 'center',
  fontStyle: 'normal',
  fontSize: '20px',
  fontWeight: 700,
  color: '#EE2D76',
  letterSpacing: 1,
};

const input: SxProps<Theme> = {
  height: '6.5vh',
  textAlign: 'left',
  input: {
    padding: '0 1vh',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    letterSpacing: '0.1vw',
    border: 'none',
    borderRadius: '8px',
    outline: 'none',
    height: 'inherit',
  },
  borderRadius: '8px',
  border: '1px solid #3C3077',
};

const signInBox: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  textTransform: 'none',
  height: '50px',
  width: '100%',
  backgroundColor: '#EE2D76',
  textAlign: 'center',
  padding: '0 10px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  letterSpacing: '0.1vw',
  border: 'none',
  borderRadius: '8px',
  outline: 'none',
  marginTop: '16px',
  marginBottom: '26px',
  color: 'white',
  ':hover': {
    backgroundColor: '#EE2D76',
  },
};

const signInDisableBox: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  textTransform: 'none',
  height: '50px',
  width: '100%',
  backgroundColor: '#D4D9DC',
  textAlign: 'center',
  padding: '0 10px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  letterSpacing: '0.1vw',
  border: 'none',
  borderRadius: '8px',
  outline: 'none',
  marginTop: '16px',
  marginBottom: '26px',
  color: 'white',
  ':hover': {
    backgroundColor: '#D4D9DC',
  },
};

const quiltLogoIcon: SxProps<Theme> = {
  img: {
    width: '200px',
  },
  marginTop: '10px',
  marginBottom: '30px',
};

const objLogoIcon: SxProps<Theme> = {
  img: {
    width: '300px',
  },
};

const smileyIcon: SxProps<Theme> = {
  img: {
    width: '125px',
  },
};

const title: SxProps<Theme> = {
  fontFamily: 'ArchivoLight',
  fontStyle: 'normal',
  fontSize: '40px',
  marginTop: '20px',
  fontWeight: 700,
  color: 'white',
  lineHeight: '40px',
  letterSpacing: 1,
};

const titleLabel: SxProps<Theme> = {
  fontFamily: 'ArchivoLight',
  fontStyle: 'normal',
  fontSize: '40px',
  fontWeight: 700,
  color: 'white',
  lineHeight: '60px',
  letterSpacing: 1,
};

const leftContainerBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
};

const fieldBox: SxProps<Theme> = {
  marginBottom: '20px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
  },
};

export type SignInComponentProps = {
  isLoading: boolean;
  userData: LoginRequestDTO;
  onSignInClick(): void;
  onFormFieldChange(type: 'username' | 'password', value: string): void;
};

export const SignInComponent: React.FC<SignInComponentProps> = ({ userData, onSignInClick, onFormFieldChange }) => {
  return (
    <Box sx={sampleDivStyle}>
      <Grid container>
        <Grid sx={{ height: '100%' }} item xs={7}>
          <Box sx={leftContainerBox}>
            <Box sx={quiltLogoIcon}>
              <img src={quiltLogo} alt='quiltLogo' />
            </Box>
            <Box sx={objLogoIcon}>
              <img src={objectLogo} alt='quiltLogo' />
            </Box>
            <Typography align='center' variant='h3' sx={title}>
              {qwltMessages.keepingYouInformed}
              <br />
              {qwltMessages.keepingYourPatientsEngaged}
            </Typography>
            <Box sx={smileyIcon}>
              <img src={smiley} alt='quiltLogo' />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={5}>
          {/* TODO: remove all inline styles */}
          <Box sx={formBox}>
            <Box sx={formInnerBox}>
              <Typography align='center' variant='subtitle1' sx={welcomeBack}>
                {qwltMessages.welcomeBack}
              </Typography>
              {/* TODO: move label and placeholders to constants */}
              <TextField
                id='outlined-basic'
                label={qwltMessages.emailOrUsername}
                placeholder={qwltMessages.enterEmailOrUsername}
                variant='outlined'
                sx={fieldBox}
                value={userData.email}
                onChange={(event) => onFormFieldChange('username', event.target.value)}
              />
              <TextField
                id='outlined-basic'
                label={qwltMessages.password}
                placeholder={qwltMessages.enterPassword}
                type='password'
                variant='outlined'
                value={userData.password}
                onChange={(event) => onFormFieldChange('password', event.target.value)}
                sx={fieldBox}
              />
              <Button
                disabled={!userData.email || !userData.password}
                onClick={onSignInClick}
                sx={!userData.email || !userData.password ? signInDisableBox : signInBox}
              >
                {qwltMessages.signin}
              </Button>
              {/* <Typography align='left' variant='subtitle1' component='p' sx={forgotPassword}>
                {qwltMessages.forgotPassword}
              </Typography> */}
            </Box>

            {/* <Stack margin='100px'>
                <Box>
                  <Typography align='left' marginBottom='6px' variant='subtitle1' component='p' sx={labelName}>
                    {qwltMessages.emailOrUsername}
                  </Typography>
                  <Box sx={input} marginBottom='16px'>
                    <input placeholder={qwltMessages.enterEmailOrUsername} />
                  </Box>
                </Box>
                <Box>
                  <Typography align='left' marginBottom='6px' variant='subtitle1' component='p' sx={labelName}>
                    {qwltMessages.password}
                  </Typography>
                  <Box sx={input} marginBottom='16px'>
                    <input type='password' placeholder={qwltMessages.enterPassword} />
                  </Box>
                </Box>
              </Stack> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
