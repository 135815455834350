import { useNavigate } from 'react-router-dom';
import {
  BehaviourIdentifierValues,
  BehaviourMetrics,
  PatientDetailsComponent,
} from '../../components/patient/PatientDetails';
import { useContext, useEffect, useState } from 'react';
import { PATIENTS_PAGE } from '../../router';
import { BehaviourMetricsResponseDTO, Details, fetchBehaviourMetrics } from '../../dto/patient/patient';
import { localStorageLiterals, qwltMessages } from '../../common/Constants';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useAsyncEffect } from '../../hooks/use-async';
import { ApiClientContext } from '../../context/ApiClientContext';
import { AxiosResponse } from 'axios';

const container: SxProps<Theme> = {
  minWidth: '1366px',
  display: 'flex',
  width: '100%',
  height: 'calc(100vh - 60px)',
  flex: 1,
  backgroundColor: '#ECF1F4',
  alignItems: 'center',
  justifyContent: 'center',
};

const errorText: SxProps<Theme> = {
  color: 'red',
  fontSize: '20px',
};

export const PatientDetailsConatiner: React.FC = () => {
  const apiClient = useContext(ApiClientContext)!;
  const [filterWeek, setFilterWeek] = useState<number>(6);
  const [isViewAll, setIsViewAll] = useState(false);
  const [patientDetails, setPatientDetails] = useState<Details>();

  const navigate = useNavigate();

  const [behaviourMetrics, setBehaviourMetrics] = useAsyncEffect<BehaviourMetricsResponseDTO>({
    fn: async () => {
      if (patientDetails && patientDetails.userId) {
        const result = await apiClient.get<any, AxiosResponse<BehaviourMetricsResponseDTO>>(fetchBehaviourMetrics, {
          params: {
            userId: patientDetails.userId,
            rangeType: 'WEEKLY',
            rangeValue: filterWeek,
          },
        });
        return result.data as any;
      }
    },
    dependencies: [],
  });

  useEffect(() => {
    if (patientDetails || filterWeek) {
      setBehaviourMetrics();
    }
  }, [patientDetails, filterWeek]);

  useEffect(() => {
    const patient = localStorage.getItem(localStorageLiterals.patientDetails);
    if (patient) {
      const patientJson = JSON.parse(patient);
      setPatientDetails(patientJson);
    }
  }, []);

  const handleViewClick = () => {
    setIsViewAll(!isViewAll);
  };

  const handleBackClick = () => {
    navigate(PATIENTS_PAGE);
  };

  const getAbbreviatedMonth = (dateString: Date): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { month: 'short' };
    return date.toLocaleDateString('en-US', options).split(' ')[0];
  };

  const formatDate = (dateString: Date): string => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month: string = getAbbreviatedMonth(dateString);

    return `${month} ${day}`;
  };

  const calculatedDates: { startDate: Date; endDate: Date }[] =
    behaviourMetrics.result?.metrics?.reduce((acc: { startDate: Date; endDate: Date }[], metric: BehaviourMetrics) => {
      const dates: { startDate: Date; endDate: Date }[] = metric.rangeValues.map(
        (value: BehaviourIdentifierValues) => ({
          startDate: new Date(value.startDate),
          endDate: new Date(value.endDate),
        })
      );
      return acc.concat(dates);
    }, []) || [];

  const totalStartDate = calculatedDates.reduce(
    (minDate: Date | null, currentDate: { startDate: Date; endDate: Date }) => {
      return minDate === null || currentDate.startDate < minDate ? currentDate.startDate : minDate;
    },
    null
  );
  const totalEndDate = calculatedDates.reduce(
    (maxDate: Date | null, currentDate: { startDate: Date; endDate: Date }) => {
      return maxDate === null || currentDate.endDate > maxDate ? currentDate.endDate : maxDate;
    },
    null
  );

  return (
    <>
      {behaviourMetrics.error && (
        <Box sx={container}>
          <Typography sx={errorText}>{qwltMessages.error}</Typography>
        </Box>
      )}
      {patientDetails && !behaviourMetrics.error && (
        <PatientDetailsComponent
          patientDetails={{
            patientName: patientDetails.patient!,
            age: patientDetails.age!,
            createdAt: patientDetails.registeredOn!,
            gender: patientDetails.gender!,
            conditions: patientDetails.userConditions
              ? patientDetails.userConditions.map((userCondition) => ({
                  conditions: userCondition.conditions.conditionName,
                  abbreviation: userCondition.conditions.abbreviation,
                }))
              : [],
            email: patientDetails.email!,
            metrics: {
              metrics: behaviourMetrics.result?.metrics ? behaviourMetrics.result?.metrics : [],
            },
            phoneNumber: patientDetails.phoneNumber!,
          }}
          isLoading={behaviourMetrics.isLoading}
          filter={{
            filterOptions: ['6 weeks', '12 weeks'],
            filterWeeks: filterWeek,
            onFilterChange: setFilterWeek,
          }}
          isViewAll={isViewAll}
          totalStartDate={formatDate(totalStartDate!)}
          totalEndDate={formatDate(totalEndDate!)}
          onBackClick={handleBackClick}
          onViewClick={handleViewClick}
        />
      )}
    </>
  );
};
