import { LoginRequestDTO, LoginResponseDTO, loginApiPath } from '../../dto/auth/signIn';
import { useContext, useEffect, useState } from 'react';

import { ApiClientContext } from '../../context/ApiClientContext';
import { AxiosResponse } from 'axios';
import { LocalStorageData } from '../../common/ApiClient';
import { PATIENTS_PAGE } from '../../router';
import { SignInComponent } from '../../components/auth/SignIn';
import { localStorageLiterals, qwltMessages } from '../../common/Constants';
import { produce } from 'immer';
import { useAsync } from '../../hooks/use-async';
import { useAuth } from '../../hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const initialSignInFormState: LoginRequestDTO = {
  email: '',
  password: '',
};

export const SignInContainer: React.FC = () => {
  const navigate = useNavigate();

  const [signInFormState, setSignInFormState] = useState<LoginRequestDTO>(initialSignInFormState);

  const apiClient = useContext(ApiClientContext)!;
  const { isAuthenticated } = useAuth();

  // TODO: move to reusable route guard
  if (isAuthenticated) {
    return null;
  }

  const [loginResult, loginCallable] = useAsync<LoginResponseDTO>({
    fn: async (data: LoginRequestDTO) => {
      const result = await apiClient.post<LoginRequestDTO, AxiosResponse<LoginResponseDTO, any>, any>(
        loginApiPath,
        data
      );
      return result.data;
    },
  });

  useEffect(() => {
    const response = loginResult.result;
    if (response) {
      const localData: LocalStorageData = {
        token: response.sessionToken,
        clinicId: response.clinicId,
        doctorId: response.doctorId,
      };
      localStorage.setItem(localStorageLiterals.authDetails, JSON.stringify(localData));
      navigate(PATIENTS_PAGE);
    }
  }, [loginResult.result]);

  useEffect(() => {
    if (loginResult.error) {
      toast.error(
        loginResult.error.message === qwltMessages.invalidInput
          ? 'Invalid Username or Password'
          : loginResult.error.message,
        {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          theme: 'light',
        }
      );
    }
  }, [loginResult.error]);

  const handleSignInClick = async () => {
    if (signInFormState.email === '' || signInFormState.password === '') {
      // TODO: relace with toastr laterP
      toast.error('Please enter the username or password', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'light',
      });
    } else {
      await loginCallable(signInFormState);
    }
  };

  const handleFormFieldChange = (type: 'username' | 'password', value: string) => {
    switch (type) {
      case 'username':
        setSignInFormState(
          produce(signInFormState, (draft) => {
            if (draft) {
              draft.email = value;
            }
          })
        );
        break;
      case 'password':
        setSignInFormState(
          produce(signInFormState, (draft) => {
            if (draft) {
              draft.password = value;
            }
          })
        );
        break;
      default:
        throw new Error('Unsupported field type');
    }
  };

  return (
    <SignInComponent
      userData={signInFormState}
      isLoading={loginResult.isLoading}
      onFormFieldChange={handleFormFieldChange}
      onSignInClick={handleSignInClick}
    />
  );
};
