import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import { localStorageLiterals } from '../common/Constants';

export type LocalStorageData = {
  token: string;
  clinicId: string;
  doctorId: string;
};

export const getUserAuthToken = (): string | undefined => {
  const user = localStorage.getItem(localStorageLiterals.authDetails);
  if (user) {
    const userJson = JSON.parse(user);
    return userJson.token;
  }
};

export const createApiClient = (baseURL: string): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL,
  });

  // auth interceptor
  axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> => {
      const authToken = getUserAuthToken();
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      } else {
        config.headers.Authorization = `Bearer ${process.env.REACT_APP_PUBLIC_API_TOKEN}`;
      }
      return config;
    }
  );

  return axiosInstance;
};
