import { Box, SxProps, Theme } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

import { HeaderComponent } from './HeaderComponent';
import { LOGIN_PAGE, NOTIFICATIONS_PAGE, PATIENTS_PAGE } from '../../router';
import { useState } from 'react';

const outerBox: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  height: '100vh',
  flexDirection: 'column',
};

export const HomeComponent: React.FC = () => {
  const navigate = useNavigate();

  const [hasNotification, setHasNotification] = useState<boolean>(true);

  const handleLogout = async () => {
    localStorage.clear();
    navigate(LOGIN_PAGE);
  };

  const handleBellIconClick = async () => {
    await navigate(NOTIFICATIONS_PAGE);
  };

  const handleQuiltIconClick = async () => {
    await navigate(PATIENTS_PAGE);
  };

  return (
    <Box sx={outerBox}>
      <HeaderComponent
        doctorName={''}
        hasNotification={hasNotification}
        onLogout={handleLogout}
        onBellIconClick={handleBellIconClick}
        onQuiltIconClick={handleQuiltIconClick}
      />
      <Box sx={{ flex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
};
