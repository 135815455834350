export const qwltMessages: Record<string, string> = {
  welcomeBack: 'Welcome back',
  emailOrUsername: 'Email / Username',
  enterEmailOrUsername: 'Enter Email / Username',
  password: 'Password',
  enterPassword: 'Enter password',
  signin: 'Sign In',
  forgotPassword: 'Forgot Password?',
  keepingYouInformed: 'Keeping you informed',
  keepingYourPatientsEngaged: 'Keeping your patients engaged',
  logout: 'Logout',
  addPatient: 'Add patient',
  addNewPatient: 'Add new patient',
  firstName: 'First name',
  lastName: 'Last name',
  emailOptional: 'Email ID (optional)',
  phoneNumber: 'Phone number',
  patientOptional: 'Patient ID (optional)',
  clinician: 'Clinician',
  enterFirstName: 'Enter first name',
  enterLastName: 'Enter last name',
  enterEmailOptional: 'Enter Email ID',
  enterPhoneNumber: 'Enter phone number',
  enterPatientOptional: 'Enter patient ID',
  selectConditions: 'Select conditions',
  search: 'Search',
  conditions: 'Conditions',
  from: 'From',
  to: 'To',
  myPatients: 'My Patients',
  allPatients: 'All Patients',
  sNo: '#',
  patient: 'Patient',
  patientId: 'Patient ID',
  registeredOn: 'Registered On',
  view: 'View',
  total: ' total',
  addPatientText: 'Add Patient',
  mypatients: 'My Patients',
  clearAll: 'Clear All',
  patientDetails: 'Patient details',
  id: 'ID',
  registeredOnText: 'Registered on',
  behaviorTracking: 'Behaviour tracking',
  viewAll: 'View All',
  viewLess: 'View Less',
  conditionLength: '4',
  invalidInput: 'Invalid input. Both email & password should be given',
  error: 'Internal server error',
  patientStatusUpdates: 'Patient Status Updates',
  metric: 'Metric',
  viewGraph: 'View Graph',
  viewProfile: 'View Profile',
  noNotifications: 'No notifications available',
  errorOccurred: 'Some error occurred',
};

export const localStorageLiterals: Record<string, string> = {
  authDetails: 'authData',
  patientDetails: 'patientData',
};

export const desiredOrder = [
  'PHQ-4(12 Pt. Scale)',
  'MDQ',
  'Support Group Attendance?',
  'Self-Report Stress(PSS-4 -- 16 Pt. Scale)',
  'Substance Craving(5 Pt. Scale)',
  'Average Daily Time at Home',
  'Average Daily Steps',
  'Days with > 6 Hours Off of Phone During Sleep Hours (out of 7 days)',
  'Average Phone Use After 6pm',
  'Circadian Routine(0 to 1) -- Higher is Stabler Routine',
  'Average Positive Affect(25 Pt. Scale)',
  'Average Negative Affect(25 Pt. Scale)',
];
