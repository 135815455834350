export const fetchNotifications =
  'https://qfwqrjcie0.execute-api.us-east-1.amazonaws.com/dev/portal-notifications/getPatientStatusNotificationsForClinic';

export type NotificationsResponse = {
  notifications: Notification[];
  totaPages: number;
  currentPage: number;
  totalItems: number;
};

export type NotificationsRequestDTO = {
  clinicId: string;
  doctorId: string;
};

export type Notification = { title: string; status: boolean; date: Date; graphData: any; profileData: any };
