import { AddPatientComponent, FormFieldType } from '../../components/patient/AddPatient';
import { AddPatientRequestDTO, AddPatientResponseDTO, addPatientApiPath } from '../../dto/patient/addPatient';
import { Draft, produce } from 'immer';
import {
  FetchConditionsResponseDTO,
  FetchDoctorsResponseDTO,
  fetchConditionsApiPath,
  fetchDoctorsList,
} from '../../dto/patient/patient';
import { LOGIN_PAGE, PATIENTS_PAGE } from '../../router';
import { useAsync, useAsyncEffect } from '../../hooks/use-async';
import { useContext, useEffect, useState } from 'react';

import { ApiClientContext } from '../../context/ApiClientContext';
import { AxiosResponse } from 'axios';
import { useAuth } from '../../hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export type AddPatientFormFieldState = {
  firstName: string;
  lastName: string;
  countryCode: string;
  phoneNumber: string;
  selectedConditions: string[];
  doctorId: string;
  emailId?: string;
  patientId?: string;
};

export const AddPatientContainer: React.FC = () => {
  const apiClient = useContext(ApiClientContext)!;
  const navigate = useNavigate();

  const { isAuthenticated, currentUser } = useAuth();

  // TODO: move to reusable route guard
  if (!isAuthenticated) {
    navigate(LOGIN_PAGE);
  }

  const [conditionsList] = useAsyncEffect<FetchConditionsResponseDTO>({
    fn: async () => {
      const result = await apiClient.get<any, AxiosResponse<FetchConditionsResponseDTO>>(fetchConditionsApiPath);
      return result.data;
    },
    dependencies: [],
  });

  const [doctorsList] = useAsyncEffect<FetchDoctorsResponseDTO>({
    fn: async () => {
      const result = await apiClient.get<any, AxiosResponse<FetchDoctorsResponseDTO>>(fetchDoctorsList, {
        params: {
          clinicId: currentUser.clinicId,
        },
      });
      return result.data;
    },
    dependencies: [],
  });

  const [addPatientResult, addPatientCallable] = useAsync<AddPatientResponseDTO>({
    fn: async (data: AddPatientRequestDTO) => {
      const result = await apiClient.post<AddPatientRequestDTO, AxiosResponse<AddPatientResponseDTO, any>, any>(
        addPatientApiPath,
        data
      );
      return result.data;
    },
  });

  useEffect(() => {
    if (addPatientResult.result) {
      // TODO: later move to constants
      // TODO: show toastr later
      toast.success('User added successfully', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'light',
      });
      navigate(PATIENTS_PAGE);
    }
  }, [addPatientResult]);

  useEffect(() => {
    if (addPatientResult.error) {
      toast.error(addPatientResult.error ? (addPatientResult.error as any).message : 'Internal Server Error', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'light',
      });
    }
  }, [addPatientResult.error]);

  const [formFieldState, setFormFieldState] = useState<AddPatientFormFieldState>({
    firstName: '',
    lastName: '',
    countryCode: '+1',
    phoneNumber: '',
    doctorId: '',
    selectedConditions: [],
  });

  const handleFormFieldChange = (type: FormFieldType, value: string) => {
    setFormFieldState((prevState) => {
      return produce(prevState, (draft: Draft<AddPatientFormFieldState>) => {
        let numericValue;
        switch (type) {
          case 'condition-checked':
            if (!draft.selectedConditions.includes(value)) {
              draft.selectedConditions.push(value);
            }
            break;
          case 'condition-unchecked':
            draft.selectedConditions = draft.selectedConditions.filter((item) => item !== value);
            break;
          case 'country-code':
            draft.countryCode = value;
            break;
          case 'phone-number':
            numericValue = value.replace(/\D/g, '');
            draft.phoneNumber = numericValue;
            break;
          case 'first-name':
            draft.firstName = value;
            break;
          case 'last-name':
            draft.lastName = value;
            break;
          case 'email':
            draft.emailId = value;
            break;
          case 'country-clinician':
            draft.doctorId = value;
            break;
          default:
            throw new Error('Unsupported field type');
        }
      });
    });
  };

  // TODO: later move to utils
  const createRequestDTO = (): AddPatientRequestDTO => {
    return {
      email: formFieldState.emailId ?? '',
      firstName: formFieldState.firstName,
      lastName: formFieldState.lastName,
      countryCode: formFieldState.countryCode,
      phoneNumber: formFieldState.phoneNumber,
      patientId: formFieldState.patientId ?? '',
      clinicId: currentUser.clinicId,
      doctorId: formFieldState.doctorId,
      conditions: formFieldState.selectedConditions,
    };
  };

  const handleSubmit = async () => {
    // TODO: later move all this validation to utils
    // TODO: later use toasts

    // 1. Check if first name is not empty
    if (formFieldState.firstName.trim() === '') {
      toast.error('Please enter your first name.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'light',
      });
      return;
    }

    // 2. Check if last name is not empty
    if (formFieldState.lastName.trim() === '') {
      toast.error('Please enter your last name.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'light',
      });
      return;
    }

    // 3. Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formFieldState.emailId && !emailRegex.test(formFieldState.emailId)) {
      toast.error('Please enter a valid email address.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'light',
      });
      return;
    }

    // 4. Check if phone number contains only digits and has a maximum length of 9
    const phoneNumberRegex = /^[0-9]{10}$/;
    if (!phoneNumberRegex.test(formFieldState.phoneNumber.trim())) {
      toast.error('Please enter a valid 10-digit USA phone number.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'light',
      });
      return;
    }

    // 5. Check if selectedConditions has at least 1 item
    if (formFieldState.selectedConditions.length === 0) {
      toast.error('Please select at least one condition.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'light',
      });
      return;
    }

    // 6. Check if clinicain is selected
    if (!formFieldState.doctorId) {
      toast.error('Please select a clinician', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        theme: 'light',
      });
    }

    const addPatientRequestBody = createRequestDTO();
    await addPatientCallable(addPatientRequestBody);
  };

  const handleBackClick = () => {
    navigate(PATIENTS_PAGE);
  };

  return (
    <AddPatientComponent
      isLoading={addPatientResult.isLoading}
      formState={formFieldState}
      conditionsList={conditionsList}
      doctorsList={doctorsList}
      onFormFieldChange={handleFormFieldChange}
      onBackClick={handleBackClick}
      onSubmit={handleSubmit}
    />
  );
};
