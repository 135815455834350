import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { AddPatientContainer } from './containers/patient/AddPatient';
import { HomeContainer } from './containers/common/HomeContainer';
import { PatientListContainer } from './containers/patient/PatientList';
import { SignInContainer } from './containers/auth/SignIn';
import { localStorageLiterals } from './common/Constants';
import { useEffect } from 'react';
import { PatientDetailsConatiner } from './containers/patient/PatientDetails';
import { NotificationsContainer } from './containers/patient/Notifications';

export const LOGIN_PAGE = '/login';
export const HOME_PAGE = '/home';
export const PATIENTS_PAGE = '/home/patients';
export const ADD_PATIENTS_PAGE = '/home/add-patient';
export const PATIENT_DETAILS = '/home/patient-details';
export const NOTIFICATIONS_PAGE = '/home/notifications';

export const AppRoutes: React.FC = () => {
  // TODO: handle this in respective components
  const isAuthenticated = !!localStorage.getItem(localStorageLiterals.authDetails);

  const navigate = useNavigate();
  const location = useLocation();

  const checkAndRedirect = (route?: string) => {
    if (route) {
      // if we have a matching route
      if (isAuthenticated) {
        navigate(route);
      } else {
        navigate(LOGIN_PAGE);
      }
    } else {
      // if we dont have a matching route
      if (isAuthenticated) {
        navigate(PATIENTS_PAGE);
      } else {
        navigate(LOGIN_PAGE);
      }
    }
  };

  useEffect(() => {
    const currentRoute = location.pathname;
    switch (currentRoute) {
      case PATIENTS_PAGE:
        checkAndRedirect(currentRoute);
        break;
      case ADD_PATIENTS_PAGE:
        checkAndRedirect(currentRoute);
        break;
      case PATIENT_DETAILS:
        checkAndRedirect(currentRoute);
        break;
      case NOTIFICATIONS_PAGE:
        checkAndRedirect(currentRoute);
        break;
      default:
        checkAndRedirect();
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path={LOGIN_PAGE} element={<SignInContainer />} />
      <Route path={HOME_PAGE} element={<HomeContainer />}>
        <Route index path={PATIENTS_PAGE} element={<PatientListContainer />} />
        <Route index path={ADD_PATIENTS_PAGE} element={<AddPatientContainer />} />
        <Route index path={PATIENT_DETAILS} element={<PatientDetailsConatiner />} />
        <Route index path={NOTIFICATIONS_PAGE} element={<NotificationsContainer />} />
      </Route>
    </Routes>
  );
};
