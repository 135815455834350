/* eslint-disable @typescript-eslint/no-unused-vars */
import { Autocomplete, Box, CircularProgress, SxProps, TextField, Theme, Typography } from '@mui/material';
import React from 'react';
import { qwltMessages } from '../../common/Constants';
import { DatePickerComponent } from '../common/DatePicker';
import { mockMetricsData } from '../../regression/MockPatientsData';
import { Notification } from '../../dto/notification';
import { groupAndSortNotifications } from '../../utils/NotificationDate';

const outerDialogBox: SxProps<Theme> = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
};

const innerDialogBox: SxProps<Theme> = {
  display: 'flex',
  width: '90%',
  height: '90%',
  borderRadius: '10px',
  backgroundColor: 'white',
  flexDirection: 'column',
};

const searchField: SxProps<Theme> = {
  width: '250px',
  height: '55px',
  mr: 3,
  borderRadius: '8px',
  border: '1px solid #8C8CA1',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
      border: 'none',
    },
    '&:hover fieldset': {
      borderRadius: '8px',
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      borderRadius: '8px',
      border: '1px solid #8C8CA1',
    },
  },
};

const dialogBoxHeader: SxProps<Theme> = {
  display: 'flex',
  height: '90px',
  backgroundColor: 'white',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
};

const addPatientText: SxProps<Theme> = {
  flex: 1,
  fontFamily: 'ArchivoLight',
  letterSpacing: '0.5px',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '10px',
};

const breadcrumbText: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  ml: 2,
  flex: 1,
};

const notificationItem: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: 1,
  borderBottom: '1px solid #ddd',
  padding: '20px',
  cursor: 'pointer',
};

const notificationStatus: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

const notificationTitle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Archivo',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '25px',
  letterSpacing: '0.2px',
  textAlign: 'left',
};

const notificationButtonGroup: SxProps<Theme> = {
  display: 'flex',
};

const linkStyle: SxProps<Theme> = {
  fontSize: '13px',
  fontWeight: 700,
  lineHeight: '17px',
  letterSpacing: '0.04em',
  cursor: 'pointer',
  color: '#1A1427',
  marginRight: '25px',
};

const circle: SxProps<Theme> = {
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  marginRight: '10px',
};

const dateStyle: SxProps<Theme> = {
  mt: '20px',
  fontSize: '17px',
  fontWeight: 600,
  lineHeight: '25px',
  letterSpacing: '0px',
  textAlign: 'left',
  color: '#1A1427',
  borderBottom: '1px solid #ddd',
  paddingBottom: '10px',
};

const loadingContainer: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '100px',
};

const errorContainer: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '100px',
};

export type NotificationsProps = {
  notifications: Array<Notification>;
  selectedItem: Notification[];
  isLoading: boolean;
  hasError?: boolean;
  onItemClick(notification: Notification): void;
  onViewProfileClick(notification: Notification): void;
};

export const NotificationsComponent: React.FC<NotificationsProps> = ({
  notifications,
  isLoading,
  selectedItem,
  hasError,
  onItemClick,
  onViewProfileClick,
}) => {
  const { groupedNotifications, sortedDates } = groupAndSortNotifications(notifications);

  return (
    <Box sx={outerDialogBox}>
      <Box sx={innerDialogBox}>
        <Box sx={dialogBoxHeader}>
          <Box sx={breadcrumbText}>
            <Typography align='left' variant='subtitle1' component='p' sx={addPatientText}>
              {qwltMessages.patientStatusUpdates}
            </Typography>
          </Box>
          {/* <Autocomplete
            key={1}
            disablePortal
            multiple
            filterSelectedOptions
            id='combo-box-demo'
            options={mockMetricsData}
            getOptionLabel={(option) => option.name}
            value={[]}
            onChange={() => {
              ('');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={qwltMessages.metric}
                placeholder={qwltMessages.metric}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <React.Fragment />,
                  endAdornment: null,
                }}
              />
            )}
            sx={searchField}
          />
          <DatePickerComponent label='From' />
          <DatePickerComponent label='To' /> */}
        </Box>
        {isLoading && (
          <Box sx={loadingContainer}>
            <Typography variant='h6'>
              {' '}
              <CircularProgress
                size='35px'
                sx={{ color: (theme) => theme.palette.common.black, alignSelf: 'center' }}
              />
            </Typography>
          </Box>
        )}
        {!isLoading && !hasError && notifications.length === 0 && (
          <Box sx={errorContainer}>
            <Typography variant='h6'>{qwltMessages.noNotifications}</Typography>
          </Box>
        )}
        {hasError && (
          <Box sx={errorContainer}>
            <Typography variant='h6'>{qwltMessages.errorOccurred}</Typography>
          </Box>
        )}
        {!isLoading && !hasError && notifications.length > 0 && (
          <Box sx={{ flex: 1, paddingLeft: '20px', paddingRight: '20px' }}>
            {sortedDates.map((date) => (
              <React.Fragment key={date}>
                <Typography variant='h6' sx={dateStyle}>
                  {new Date(date)
                    .toLocaleDateString('en', { day: '2-digit', month: 'short' })
                    .split(' ')
                    .reverse()
                    .join(' ')}
                </Typography>
                {groupedNotifications[date].map((notification, index) => (
                  <Box
                    key={index}
                    sx={{
                      ...notificationItem,
                      backgroundColor: selectedItem.includes(notification) ? '#ECF1F4' : 'white',
                    }}
                    onClick={() => onItemClick(notification)}
                  >
                    <Box sx={notificationStatus}>
                      <Box sx={{ ...circle, backgroundColor: notification.status ? 'green' : 'red' }} />
                      <Typography sx={notificationTitle}>{notification.title}</Typography>
                    </Box>
                    <Box sx={notificationButtonGroup}>
                      {/* <Typography
                        variant='body2'
                        sx={linkStyle}
                        onClick={() => {
                          ('');
                        }}
                      >
                        {qwltMessages.viewGraph}
                      </Typography> */}
                      {/* <Typography variant='body2' sx={linkStyle} onClick={() => onViewProfileClick(notification)}>
                        {qwltMessages.viewProfile}
                      </Typography> */}
                    </Box>
                  </Box>
                ))}
              </React.Fragment>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
