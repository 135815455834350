import { LocalStorageData } from '../common/ApiClient';
import { localStorageLiterals } from '../common/Constants';
import { useState } from 'react';

export const useAuth = () => {
  const [currentUser] = useState<LocalStorageData>(() => {
    const storedUserData = localStorage.getItem(localStorageLiterals.authDetails);
    return storedUserData ? JSON.parse(storedUserData) : null;
  });

  const isAuthenticated = !!currentUser;

  return {
    isAuthenticated,
    currentUser,
  };
};
