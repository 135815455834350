import { ADD_PATIENTS_PAGE, LOGIN_PAGE } from '../../router';
import { AllPatientsRequestDTO, AllPatientsResponseDTO, fetchPatientsApiList } from '../../dto/patient/patient';
import { useContext, useEffect, useState } from 'react';

import { ApiClientContext } from '../../context/ApiClientContext';
import { AxiosResponse } from 'axios';
import { GridSortModel } from '@mui/x-data-grid';
import { PatientListComponent } from '../../components/patient/PatientList';
import { useAsync } from '../../hooks/use-async';
import { useAuth } from '../../hooks/use-auth';
import { useNavigate } from 'react-router-dom';

export type PaginationModel = {
  page: number;
  pageSize: number;
};

export const PatientListContainer: React.FC = () => {
  const apiClient = useContext(ApiClientContext)!;
  const { isAuthenticated, currentUser } = useAuth();
  const [sortValue, setSortValue] = useState<GridSortModel>([
    {
      field: 'registeredOn',
      sort: 'desc',
    },
  ]);

  const [searchInput, setSearchInput] = useState<string>('');

  const navigate = useNavigate();

  // TODO: move to reusable route guard
  if (!isAuthenticated) {
    navigate(LOGIN_PAGE);
  }

  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const handleSortChange = (sortModel: GridSortModel) => {
    setSortValue(sortModel);
  };

  const handleSearchClick = async () => {
    const sort = sortValue.map((sort) => {
      return {
        field: sort.field === 'patient' ? 'name' : sort.field === 'registeredOn' ? 'createdAt' : sort.field,
        value: sort.sort,
      };
    });
    await getPatientList({
      clinicId: currentUser.clinicId,
      searchLiteral: searchInput,
      page: 0,
      pageSize: 25,
      sort: sort,
    });
  };

  const handleSearchInputChange = (event: string) => {
    setSearchInput(event);
    const sort = sortValue.map((sort) => {
      return {
        field: sort.field === 'patient' ? 'name' : sort.field === 'registeredOn' ? 'createdAt' : sort.field,
        value: sort.sort,
      };
    });
    if (event === '') {
      getPatientList({
        clinicId: currentUser.clinicId,
        page: 0,
        pageSize: 25,
        sort: sort,
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handlePaginationModelChange = (newPaginationModel: PaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleAddPatientClick = () => {
    navigate(ADD_PATIENTS_PAGE);
  };

  const [patientsList, getPatientList] = useAsync<AllPatientsResponseDTO>({
    fn: async (data: AllPatientsRequestDTO) => {
      const result = await apiClient.post<any, AxiosResponse<AllPatientsResponseDTO>>(fetchPatientsApiList, data);
      return result.data;
    },
  });

  useEffect(() => {
    (async () => {
      const sort = sortValue.map((sort) => {
        return {
          field: sort.field === 'patient' ? 'name' : sort.field === 'registeredOn' ? 'createdAt' : sort.field,
          value: sort.sort,
        };
      });
      await getPatientList({
        clinicId: currentUser.clinicId,
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        sort: sort,
      });
    })();
  }, [paginationModel]);

  return (
    <PatientListComponent
      patientList={patientsList}
      paginationModel={paginationModel}
      sortValue={sortValue}
      searchInput={searchInput}
      onSortChange={handleSortChange}
      onPaginationModelChange={handlePaginationModelChange}
      onAddPatientClick={handleAddPatientClick}
      onSearchClick={handleSearchClick}
      onSearchInputChange={handleSearchInputChange}
      onHandleKeyDown={handleKeyDown}
    />
  );
};
