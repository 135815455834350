import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

import { AddPatientFormFieldState } from '../../containers/patient/AddPatient';
import { AsyncState } from '../../hooks/use-async';
import CircularProgress from '@mui/material/CircularProgress';
import { FetchConditionsResponseDTO, FetchDoctorsResponseDTO } from '../../dto/patient/patient';
import React from 'react';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import { qwltMessages } from '../../common/Constants';

const outerLayout: SxProps<Theme> = {
  minWidth: '1366px',
  display: 'flex',
  width: '100%',
  height: 'calc(100vh - 60px)',
  backgroundColor: '#ECF1F4',
};

const innerLayout: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
};

const dialogBox: SxProps<Theme> = {
  display: 'flex',
  height: '90px',
  backgroundColor: 'white',
  flexDirection: 'row',
  alignItems: 'center',
};

const fieldContainer: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
};

const fieldInnerContainer: SxProps<Theme> = {
  flex: 1,
};

const outerDialogBox: SxProps<Theme> = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
};

const innerDialogBox: SxProps<Theme> = {
  display: 'flex',
  width: '87%',
  height: '90%',
  borderRadius: '10px',
  backgroundColor: 'white',
  flexDirection: 'column',
  paddingRight: '26px',
  paddingLeft: '26px',
  paddingBottom: '26px',
};

const arrowLeftIcon: SxProps<Theme> = {
  img: {
    width: '25px',
  },
  marginRight: '5px',
  cursor: 'pointer',
};

const containerBox: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  height: '100px',
  alignSelf: 'center',
  width: '90%',
};

const labelName: SxProps<Theme> = {
  textTransform: 'none',
  fontFamily: 'ArchivoExtraLight',
  fontStyle: 'normal',
  fontSize: '18px',
  fontWeight: 700,
  color: 'black',
  letterSpacing: '1.5px',
  cursor: 'pointer',
};

const addNewPatient: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  fontWeight: 700,
  fontStyle: 'normal',
  fontSize: '28px',
  lineHeight: '16px',
};

const checkboxContainer: SxProps<Theme> = {
  flex: 1,
  maxHeight: '420px',
  overflow: 'auto',
};

const checkboxContainerField: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

const checkbox: SxProps<Theme> = {
  '& .MuiSvgIcon-root': {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
  },
  '&.Mui-checked': {
    color: '#31d0aa',
  },
  position: 'relative',
};

const addPatientBox: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  textTransform: 'none',
  height: '55px',
  width: '40%',
  alignSelf: 'center',
  backgroundColor: '#EE2D76',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  letterSpacing: '1.5px',
  border: 'none',
  borderRadius: '6px',
  outline: 'none',
  marginTop: '15px',
  color: 'white',
  ':hover': {
    backgroundColor: '#EE2D76',
  },
};

const fieldBox: SxProps<Theme> = {
  width: '90%',
  marginBottom: '14px',
  marginTop: '6px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
  },
};

const fieldBoxClinician: SxProps<Theme> = {
  width: '90%',
  marginBottom: '20px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
      marginTop: '0', // Remove the top margin for the border
    },
    '&:hover fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(8px, -12px)',
    backgroundColor: 'white',
    paddingLeft: '2px',
    zIndex: 1,
  },
};

const phoneSelectFieldBox: SxProps<Theme> = {
  marginBottom: '20px',
  width: '90px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
  },
};

const phoneInputField: SxProps<Theme> = {
  marginBottom: '20px',
  width: '90%',
  marginLeft: '1%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '8px',
    },
  },
};

export type FormFieldType =
  | 'country-code'
  | 'phone-number'
  | 'first-name'
  | 'last-name'
  | 'condition-checked'
  | 'condition-unchecked'
  | 'email'
  | 'country-clinician';

export type AddPatientComponentProps = {
  isLoading: boolean;
  formState: AddPatientFormFieldState;
  conditionsList: AsyncState<FetchConditionsResponseDTO>;
  doctorsList: AsyncState<FetchDoctorsResponseDTO>;
  onFormFieldChange(type: FormFieldType, value: string): void;
  onBackClick(): void;
  onSubmit(): void;
};

export const AddPatientComponent: React.FC<AddPatientComponentProps> = ({
  isLoading,
  formState,
  doctorsList,
  conditionsList,
  onBackClick,
  onFormFieldChange,
  onSubmit,
}) => {
  const countryCodes = ['+44', '+91', '+1', '+61', '+995'];
  return (
    <Box sx={outerLayout}>
      <Grid container>
        <Grid sx={innerLayout} item xs={12}>
          <Box sx={containerBox}>
            <Box onClick={onBackClick} sx={arrowLeftIcon}>
              <img src={arrowLeft} alt='quiltLogo' />
            </Box>
            <Button sx={labelName}>{qwltMessages.addPatient}</Button>
          </Box>
          <Grid item xl={12}>
            <Box sx={outerDialogBox}>
              <Box sx={innerDialogBox}>
                <Box sx={dialogBox}>
                  <Typography align='center' variant='subtitle1' component='p' sx={addNewPatient}>
                    {qwltMessages.addNewPatient}
                  </Typography>
                </Box>
                <Grid item xs={12}>
                  <Box sx={fieldContainer}>
                    <Box sx={fieldInnerContainer}>
                      <Grid item xs={12}>
                        <TextField
                          id='outlined-basic'
                          label={qwltMessages.firstName}
                          placeholder={qwltMessages.enterFirstName}
                          variant='outlined'
                          value={formState.firstName}
                          onChange={(event) => onFormFieldChange('first-name', event.target.value)}
                          sx={fieldBox}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id='outlined-basic'
                          label={qwltMessages.lastName}
                          placeholder={qwltMessages.enterLastName}
                          variant='outlined'
                          value={formState.lastName}
                          onChange={(event) => onFormFieldChange('last-name', event.target.value)}
                          sx={fieldBox}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id='outlined-basic'
                          label={qwltMessages.emailOptional}
                          placeholder={qwltMessages.enterEmailOptional}
                          variant='outlined'
                          value={formState.emailId ?? ''}
                          onChange={(event) => onFormFieldChange('email', event.target.value)}
                          sx={fieldBox}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', width: '90%' }}>
                          <TextField
                            select
                            id='country-code-select'
                            variant='outlined'
                            value={formState.countryCode}
                            onChange={(event) => onFormFieldChange('country-code', event.target.value)}
                            SelectProps={{
                              native: true,
                            }}
                            sx={phoneSelectFieldBox}
                          >
                            {countryCodes.map((code) => (
                              <option key={code} value={code}>
                                {code}
                              </option>
                            ))}
                          </TextField>
                          <TextField
                            id='outlined-basic'
                            inputMode='numeric'
                            type='text'
                            label={qwltMessages.phoneNumber}
                            placeholder={qwltMessages.enterPhoneNumber}
                            variant='outlined'
                            value={formState.phoneNumber}
                            onChange={(event) => onFormFieldChange('phone-number', event.target.value)}
                            sx={phoneInputField}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id='outlined-basic'
                          label={qwltMessages.patientOptional}
                          placeholder={qwltMessages.enterPatientOptional}
                          variant='outlined'
                          sx={fieldBox}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          sx={{
                            ...(formState.doctorId ? fieldBoxClinician : fieldBox),
                            '& .MuiInputLabel-root': {
                              transform: 'translate(14px, 18px) scale(1)',
                            },
                            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                              transform: 'translate(8px, -11px)',
                              backgroundColor: 'white',
                              paddingLeft: '3px',
                              zIndex: 1,
                              fontSize: '14px',
                              paddingRight: '3px',
                            },
                          }}
                        >
                          <InputLabel id='demo-simple-select-filled-label'>{qwltMessages.clinician}</InputLabel>
                          <Select
                            id='country-clinician'
                            variant='outlined'
                            value={formState.doctorId}
                            placeholder={formState.doctorId ? '' : qwltMessages.clinician}
                            onChange={(event) => onFormFieldChange('country-clinician', event.target.value)}
                          >
                            {doctorsList.result?.data.map((doctor, index) => (
                              <MenuItem value={doctor.id} key={index}>
                                {doctor.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Box>
                    <Box sx={fieldInnerContainer}>
                      <Grid item xs={12}>
                        <Box sx={{ width: '90%' }}>
                          <Typography align='left' marginBottom='6px' variant='subtitle1' component='p' sx={labelName}>
                            {qwltMessages.selectConditions}
                          </Typography>
                          <Box sx={checkboxContainer}>
                            {conditionsList.result?.data.map((condition, index) => (
                              <Box key={index} sx={checkboxContainerField}>
                                <Checkbox
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      onFormFieldChange('condition-checked', condition.id);
                                    } else {
                                      onFormFieldChange('condition-unchecked', condition.id);
                                    }
                                  }}
                                  color='default'
                                  sx={checkbox}
                                />
                                <Typography component='label' sx={labelName}>
                                  {condition.conditionName}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                  <Box sx={fieldContainer}>
                    <Grid item xs={12}>
                      <Stack>
                        <Button onClick={onSubmit} sx={addPatientBox}>
                          {isLoading ? (
                            <CircularProgress size='25px' sx={{ color: (theme) => theme.palette.common.white }} />
                          ) : (
                            qwltMessages.addPatientText
                          )}
                        </Button>
                      </Stack>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
