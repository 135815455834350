/* eslint-disable react/prop-types */
// TODO: disable above eslint check in eslintconfig
import { Box, Button, Grid, SxProps, Theme, Typography } from '@mui/material';

import quiltLogo from '../../assets/icons/quiltLogo.svg';
import { qwltMessages } from '../../common/Constants';
import { ReactComponent as Bell } from '../../assets/icons/bell.svg';
import { ReactComponent as Ellipse } from '../../assets/icons/Ellipse.svg';

export type HeaderComponentProps = {
  doctorName: string;
  hasNotification: boolean;
  onLogout(): void;
  onBellIconClick(): void;
  onQuiltIconClick(): void;
};

const headerBox: SxProps<Theme> = {
  minHeight: '60px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'white',
  justifyContent: 'center',
  borderBottom: 'solid 1px silver',
};

const headerInnerBox: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
};

const rightOuterBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

const quiltLogoIcon: SxProps<Theme> = {
  img: {
    width: '110px',
  },
  cursor: 'pointer',
};

const doctorNameText: SxProps<Theme> = {
  alignItems: 'center',
  fontFamily: 'ArchivoExtraLight',
  fontStyle: 'normal',
  fontSize: '16px',
  fontWeight: 700,
  color: 'black',
  letterSpacing: 1,
  marginRight: '40px',
};

const logoutButton: SxProps<Theme> = {
  textTransform: 'none',
  alignItems: 'center',
  fontFamily: 'ArchivoExtraLight',
  fontStyle: 'normal',
  fontSize: '20px',
  fontWeight: 700,
  color: '#40256B',
  letterSpacing: 1,
  cursor: 'pointer',
  marginLeft: '20px',
};

const doctorNameGrid: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

const containerBox: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  width: '90%',
  height: '100px',
  alignSelf: 'center',
  justifyContent: 'space-between',
};

const notificationStyle: SxProps<Theme> = {
  position: 'relative',
  top: '-10px',
  left: '20px',
  cursor: 'pointer',
};

export const HeaderComponent: React.FC<HeaderComponentProps> = ({
  doctorName,
  hasNotification,
  onBellIconClick,
  onQuiltIconClick,
  onLogout,
}) => {
  const showNotifications = process.env.REACT_APP_SHOW_NOTIFICATIONS! === 'true';
  return (
    <Box sx={headerBox}>
      <Grid container>
        <Grid sx={headerInnerBox} item xs={12}>
          <Box sx={containerBox}>
            <Box sx={quiltLogoIcon}>
              <img src={quiltLogo} alt='quiltLogo' onClick={onQuiltIconClick} />
            </Box>
            <Box sx={rightOuterBox}>
              <Typography align='center' sx={doctorNameText}>
                {doctorName}
              </Typography>
              {showNotifications && (
                <>
                  {hasNotification && (
                    <Box sx={notificationStyle} onClick={onBellIconClick}>
                      <Ellipse />
                    </Box>
                  )}
                  <Bell width='20px' height='20px' cursor={'pointer'} onClick={onBellIconClick} />
                </>
              )}
              <Button onClick={onLogout} sx={logoutButton}>
                {qwltMessages.logout}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
