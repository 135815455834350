import { BehaviourMetrics, BehaviourMetricsDates } from './PatientDetails';
/* eslint-disable */
import {
  Box,
  Button,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';

import graph from '../../assets/icons/graph.svg';
import redGraph from '../../assets/icons/redGraph.svg';
import { desiredOrder } from '../../common/Constants';
import { formatDate, sortByDesiredOrder } from '../../utils/NotificationDate';

const outerDialogBox: SxProps<Theme> = {
  width: '100%',
};

const metricText: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  fontSize: '17px',
  marginLeft: '10px',
  color: '#1A1427',
  fontWeight: 'bold',
};

const metricOptionText: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  fontSize: '17px',
  marginLeft: '10px',
  color: '#8C8CA1',
  fontWeight: 'bold',
};

const headerText: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  fontSize: '14px',
  marginLeft: '10px',
  color: '#8C8CA1',
  fontWeight: 'bold',
};

const isAlarmMetricText: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  fontSize: '17px',
  marginLeft: '10px',
  color: '#FF1B1B',
  fontWeight: 'bold',
};

export type BehaviourTrackingProps = {
  isViewAll: boolean;
  metrics: BehaviourMetrics[];
  metricsDate?: BehaviourMetricsDates[];
};

export const BehaviourTracking: React.FC<BehaviourTrackingProps> = ({ metrics, metricsDate, isViewAll }) => {
  const isAlarming = false;

  const metricState = metricsDate
    ? metricsDate.length > 0
      ? metricsDate
      : []
    : metrics.length > 0
    ? metrics[0].rangeValues
    : [];

  const sortedMetrics = [...metrics].sort(sortByDesiredOrder);

  const displayedMetrics = isViewAll ? sortedMetrics : sortedMetrics.slice(0, 3);

  return (
    <Box sx={outerDialogBox}>
      <TableContainer sx={{ flex: 1 }}>
        <Table style={{ flex: 1, overflowX: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={headerText} style={{ minWidth: '400px', flex: 1 }}>
                Identifier
              </TableCell>
              {metricsDate
                ? metricsDate.map((metric, index) => (
                    <TableCell key={index} sx={headerText} style={{ minWidth: '100px' }}>
                      {formatDate(new Date(metric.startDate))} - {formatDate(new Date(metric.endDate))}
                    </TableCell>
                  ))
                : metricState.map((metric, index) => (
                    <TableCell key={index} sx={headerText} style={{ minWidth: '100px' }}>
                      {formatDate(new Date(metric.startDate))} - {formatDate(new Date(metric.endDate))}
                    </TableCell>
                  ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedMetrics.map((metric, index) => (
              <TableRow key={index} style={isAlarming ? { backgroundColor: '#FFF3F3' } : {}}>
                <TableCell sx={isAlarming ? isAlarmMetricText : metricText}>{metric.identifierName}</TableCell>
                {metricState.map((headerMetricValue, headerIndex) => {
                  const matchingDate = metric.rangeValues.find((date) => {
                    const startDate = new Date(date.startDate);
                    const endDate = new Date(date.endDate);
                    const headerStartDate = new Date(headerMetricValue.startDate);
                    const headerEndDate = new Date(headerMetricValue.endDate);
                    return (
                      startDate.getTime() === headerStartDate.getTime() && endDate.getTime() === headerEndDate.getTime()
                    );
                  });

                  return (
                    <TableCell key={headerIndex} sx={isAlarming ? isAlarmMetricText : metricOptionText}>
                      {matchingDate ? matchingDate.identifierValue : '-'}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <Button>
                    {/* {isAlarming ? <img src={redGraph} alt='quiltLogo' /> : <img src={graph} alt='quiltLogo' />} */}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
