/* eslint-disable */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import call from '../../assets/icons/call.svg';
import mail from '../../assets/icons/mail.svg';
import { qwltMessages } from '../../common/Constants';
import { BehaviourTracking } from './BehaviourTracking';

const outerLayout: SxProps<Theme> = {
  minWidth: '1366px',
  display: 'flex',
  width: '100%',
  height: 'calc(100vh - 60px)',
  backgroundColor: '#ECF1F4',
};

const viewAllOuterLayout: SxProps<Theme> = {
  minWidth: '1366px',
  display: 'flex',
  width: '100%',
  minHeight: 'calc(100vh - 60px)',
  backgroundColor: '#ECF1F4',
};

const outerGrid: SxProps<Theme> = {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
};

const containerBox: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  height: '100px',
  alignSelf: 'center',
  width: '90%',
};

const conditionsBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '90%',
};

const conditionOption: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
};

const arrowLeftIcon: SxProps<Theme> = {
  img: {
    width: '25px',
  },
  cursor: 'pointer',
  marginRight: '5px',
};

const callMailIcon: SxProps<Theme> = {
  img: {
    width: '18px',
  },
  cursor: 'pointer',
  marginLeft: '10px',
};

const ageGenderBox: SxProps<Theme> = {
  display: 'flex',
  backgroundColor: '#D8E4E8',
  marginLeft: '8px',
  marginRight: '8px',
  borderRadius: '10px',
  padding: '3px',
  paddingRight: '10px',
  paddingLeft: '10px',
  textAlign: 'center',
  textAlignLast: 'center',
  fontFamily: 'ArchivoExtraLight',
  fontSize: '13px',
  color: '#1A1427',
  fontWeight: 'bold',
};

const callMailText: SxProps<Theme> = {
  textAlign: 'center',
  textAlignLast: 'center',
  fontFamily: 'ArchivoExtraLight',
  fontSize: '17px',
  marginLeft: '10px',
  color: '#1A1427',
  fontWeight: 'bold',
};

const idText: SxProps<Theme> = {
  textAlign: 'center',
  textAlignLast: 'center',
  fontFamily: 'ArchivoExtraLight',
  fontSize: '17px',
  color: '#8C8CA1',
  fontWeight: 'bold',
};

const conditionText: SxProps<Theme> = {
  textAlign: 'start',
  fontFamily: 'ArchivoLight',
  fontSize: '18px',
  color: '#1A1427',
  fontWeight: 'bold',
  marginBottom: '5px',
};

const labelName: SxProps<Theme> = {
  textTransform: 'none',
  fontFamily: 'ArchivoExtraLight',
  fontStyle: 'normal',
  fontSize: '18px',
  fontWeight: 700,
  color: 'black',
  letterSpacing: '1.5px',
};

const metricName: SxProps<Theme> = {
  textTransform: 'none',
  fontFamily: 'ArchivoExtraLight',
  fontStyle: 'normal',
  fontSize: '22px',
  fontWeight: 700,
  color: 'black',
  letterSpacing: '1px',
};

const detailsBox: SxProps<Theme> = {
  width: '90%',
  display: 'flex',
  alignSelf: 'center',
  flexDirection: 'row',
};

const metrixHeaderBox: SxProps<Theme> = {
  display: 'flex',
  alignSelf: 'center',
  flexDirection: 'row',
  pt: '20px',
  pb: '20px',
  width: '95%',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const nameBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
  paddingBottom: '15px',
};

const metricsFilterBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const callMailBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  flex: 1,
};

const addNewPatient: SxProps<Theme> = {
  fontFamily: 'ArchivoExtraLight',
  fontWeight: 700,
  fontStyle: 'normal',
  fontSize: '28px',
  lineHeight: '16px',
};

const breadcrumbOuter: SxProps<Theme> = {
  backgroundColor: ' #D8E4E8',
  color: '#1A1427',
  borderRadius: '35px',
  height: '50px',
  paddingRight: '20px',
  paddingLeft: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: '1px',
  fontWeight: '700',
  marginRight: '20px',
  cursor: 'pointer',
};

const viewAllDialogBox: SxProps<Theme> = {
  mt: '30px',
  mb: '50px',
  display: 'flex',
  width: '90%',
  minHeight: '450px',
  height: '90%',
  alignSelf: 'center',
  borderRadius: '10px',
  backgroundColor: 'white',
  flexDirection: 'column',
};

const isLoadingDialogBox: SxProps<Theme> = {
  mt: '30px',
  display: 'flex',
  width: '90%',
  height: '450px',
  alignSelf: 'center',
  borderRadius: '10px',
  backgroundColor: 'white',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};
const viewLessDialogBox: SxProps<Theme> = {
  mt: '30px',
  display: 'flex',
  width: '90%',
  height: '450px',
  alignSelf: 'center',
  borderRadius: '10px',
  backgroundColor: 'white',
  flexDirection: 'column',
};

const filterOptionBox: SxProps<Theme> = {
  ml: 1,
  minWidth: 120,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '15px',
    },
    '&:hover fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8C8CA1',
      borderWidth: '1px',
      borderRadius: '15px',
    },
  },
};

const viewButton: SxProps<Theme> = {
  p: '7px',
  pr: '20px',
  pl: '20px',
  borderRadius: '7px',
  backgroundColor: '#FFE4EE',
  color: '#EE2D76',
  fontFamily: 'ArchivoExtraLight',
  textTransform: 'none',
  fontSize: '17px',
  fontWeight: 'bold',
  letterSpacing: '1px',
  ':hover': {
    backgroundColor: '#FFE4EE',
  },
};

export type BehaviourIdentifierValues = {
  startDate: string;
  endDate: string;
  identifierValue: string;
};

export type BehaviourMetricsDates = { startDate: string; endDate: string };

export type BehaviourMetrics = {
  identifierName: string;
  rangeValues: BehaviourIdentifierValues[];
};

export type PatientDetails = {
  patientName: string;
  age: string;
  gender: string;
  createdAt: string;
  conditions: {
    conditions: string;
    abbreviation: string;
  }[];
  phoneNumber: string;
  email: string;
  metrics: {
    merticDate?: BehaviourMetricsDates[];
    metrics: BehaviourMetrics[];
  };
};

export type WeekFilter = {
  filterOptions: string[];
  filterWeeks: number;
  onFilterChange(filterWeeks: number): void;
};

export type PatientDetailsProps = {
  patientDetails: PatientDetails;
  totalStartDate: string;
  totalEndDate: string;
  filter: WeekFilter;
  isViewAll: boolean;
  isLoading: boolean;
  onViewClick(): void;
  onBackClick(): void;
};

export const PatientDetailsComponent: React.FC<PatientDetailsProps> = ({
  patientDetails,
  filter,
  isViewAll,
  totalStartDate,
  totalEndDate,
  isLoading,
  onViewClick,
  onBackClick,
}) => {
  return (
    <Box sx={isViewAll ? viewAllOuterLayout : outerLayout}>
      <Grid sx={outerGrid} item xs={12}>
        <Box sx={containerBox}>
          <Box onClick={onBackClick} sx={arrowLeftIcon}>
            <img src={arrowLeft} alt='quiltLogo' />
          </Box>
          <Typography sx={labelName}>{qwltMessages.patientDetails}</Typography>
        </Box>
        <Box sx={detailsBox}>
          <Box>
            <Box sx={nameBox}>
              <Typography align='center' variant='subtitle1' component='p' sx={addNewPatient}>
                {patientDetails.patientName}
              </Typography>
              {patientDetails.age || patientDetails.gender ? (
                <Typography sx={ageGenderBox}>
                  {patientDetails.age ? `${patientDetails.age} , ${patientDetails.gender}` : patientDetails.gender}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={nameBox}>
              <Typography sx={idText}>{qwltMessages.registeredOnText}</Typography>
              <Typography sx={callMailText}>{patientDetails.createdAt}</Typography>
            </Box>
          </Box>
          <Box sx={callMailBox}>
            <Box sx={callMailIcon}>
              <img src={call} alt='quiltLogo' />
            </Box>
            <Typography sx={callMailText}>{patientDetails.phoneNumber}</Typography>
            {patientDetails.email && (
              <>
                <Box sx={callMailIcon}>
                  <img src={mail} alt='quiltLogo' />
                </Box>
                <Typography sx={callMailText}>{patientDetails.email}</Typography>
              </>
            )}
          </Box>
        </Box>
        <Box sx={conditionsBox}>
          <Typography sx={conditionText}>{qwltMessages.conditions}</Typography>
          <Box sx={conditionOption}>
            {patientDetails.conditions.map((condition, index) => (
              <Tooltip title={condition.conditions} key={index}>
                <Typography sx={breadcrumbOuter} color='text.primary'>
                  {condition.abbreviation}
                </Typography>
              </Tooltip>
            ))}
          </Box>
        </Box>
        <Box
          sx={
            isLoading || patientDetails.metrics.metrics.length === 0
              ? isLoadingDialogBox
              : isViewAll
              ? viewAllDialogBox
              : viewLessDialogBox
          }
        >
          {isLoading ? (
            <CircularProgress size='35px' sx={{ color: (theme) => theme.palette.common.black, alignSelf: 'center' }} />
          ) : patientDetails.metrics.metrics.length === 0 ? (
            <Typography sx={conditionText}>No Metrics</Typography>
          ) : (
            <>
              <Box sx={metrixHeaderBox}>
                <Typography sx={metricName}>{qwltMessages.behaviorTracking}</Typography>
                <Box sx={metricsFilterBox}>
                  <Typography sx={idText}>
                    {totalStartDate} to {totalEndDate}
                  </Typography>
                  <FormControl sx={filterOptionBox}>
                    <Select
                      value={`${filter.filterWeeks} ${filter.filterWeeks === 1 ? 'week' : 'weeks'}`}
                      onChange={(event) => filter.onFilterChange(parseInt(event.target.value))}
                      sx={{ color: 'black', height: '40px' }}
                    >
                      {filter.filterOptions.map((filter, index) => (
                        <MenuItem key={index} value={filter}>
                          {filter}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ flex: 1, alignSelf: 'center', width: '95%', display: 'flex' }}>
                <BehaviourTracking
                  metrics={patientDetails.metrics.metrics}
                  metricsDate={patientDetails.metrics.merticDate}
                  isViewAll={isViewAll}
                />
              </Box>
              {patientDetails.metrics.metrics.length > 3 ? (
                <Box sx={metrixHeaderBox}>
                  <Button sx={viewButton} onClick={onViewClick}>
                    {isViewAll ? qwltMessages.viewLess : qwltMessages.viewAll}
                  </Button>
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
};
